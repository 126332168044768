import React from 'react';
import { Link } from 'react-router-dom';
import { display, displayUSD } from 'helpers/price';

import './ModelCard.scss';

export default function ModelCard({ model, link, linkLabel, pricePrefix, brandId }) {
  const { brand, name, image, price, webDiscount } = model;

  const displayPrice = brandId === 6 ? displayUSD : display;

  return (
    <div className={'model-card'}>
      {link !== undefined && (
        <Link to={link} className="model-card__link">
          {linkLabel}
        </Link>
      )}
      {brand && <div>{brand}</div>}
      <h3 className="model-card__model">{name.toLowerCase()}</h3>
      <div className="model-card__image">
        {image && <img src={image} alt={name} />}
      </div>
      {price && (
        <div className="model-card__price">{`${pricePrefix}${displayPrice(
          price
        )}`}</div>
      )}
      {webDiscount > 0 && (
        <div className="model-card__discount">
          Descuento web {displayPrice(webDiscount)}
        </div>
      )}
      <div className="model-card__arrowlink">Explorar versiones</div>
    </div>
  );
};
