import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Responsive from 'react-responsive';
import Button from 'components/atoms/button/index.jsx';
import HowToBuyBanner from 'components/molecules/howToBuyBanner/index.jsx';
import ToggleButton from 'components/atoms/toggleButton/index.jsx';
import Header from 'components/organisms/header/index.jsx';
import MobileNavigation from 'components/organisms/mobileNavigation/index.jsx';
import Footer from 'components/organisms/footer/index.jsx';
import Hero from 'components/organisms/hero/index.jsx';
import VersionCard from 'components/molecules/versionCard/index.jsx';
import Loader from 'components/atoms/loader';
import Units from 'components/organisms/units';
import ContactForm from 'components/organisms/contactForm';
import PriceRequest from 'components/organisms/priceRequest';
import { getHeroImage } from 'helpers/vehicle';
import subfamilyActions from 'actions/subfamily';
import { switchToBrandName } from 'actions/brand';
import stockActions from 'actions/stock';
import { parseRoute } from 'config/routes';
import brands from 'config/brands';
import { hide } from 'helpers/chat';

import './Versions.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const DesktopHeader = props => <Responsive {...props} minWidth={1024} />;
const MobileHeader = props => <Responsive {...props} maxWidth={1023} />;

class Versions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCharacteristics: false,
      showSubfamilyStock: null,
      showPriceRequest: false,
      priceRequested: sessionStorage.getItem('priceRequested')
    };

    this.handleClick = this.handleClick.bind(this);
    this.showUnits = this.showUnits.bind(this);
    this.hideUnits = this.hideUnits.bind(this);
    this.showPriceRequest = this.showPriceRequest.bind(this);
    this.hidePriceRequest = this.hidePriceRequest.bind(this);
    this.priceRequestSuccess = this.priceRequestSuccess.bind(this);
    this.scrollToVersions = this.scrollToVersions.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      showCharacteristics: !state.showCharacteristics
    }));
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.match.params.brand !== this.props.brandName) {
      this.props.switchToBrand(this.props.match.params.brand);
    }

    this.props.reset();

    this.setFamily();
  }

  componentDidUpdate(prevProps, prevState) {
    hide();

    this.restoreScroll(prevState);

    if (
      !this.state.family ||
      this.state.family.id !== this.props.match.params.id
    ) {
      this.props.reset();

      this.setFamily();

      return;
    }

    if (prevProps.stock) {
      return;
    }

    const { subfamilies, stock, fetchingStock } = this.props;

    if (subfamilies.length > 0 && !stock && !fetchingStock) {
      subfamilies.forEach(subfamily => {
        this.props.fetchStock(this.state.family.id, subfamily.id);
      });
    }
  }

  render() {
    const {
      family,
      showSubfamilyStock,
      showPriceRequest,
      priceRequested
    } = this.state;
    const {
      brandName,
      theme,
      subfamilies,
      stockBySubfamily,
      stock
    } = this.props;

    if (family === undefined) {
      return this.renderLoading();
    }

    if (family === null) {
      return this.renderNotFound();
    }

    if (showPriceRequest) {
      return (
        <PriceRequest
          family={family}
          onClose={this.hidePriceRequest}
          onSuccess={this.priceRequestSuccess}
        />
      );
    }

    if (showSubfamilyStock) {
      return (
        <Units
          family={family}
          subfamily={showSubfamilyStock}
          vehicles={stockBySubfamily[showSubfamilyStock.id].all}
          onClose={this.hideUnits}
          theme={theme}
        />
      );
    }

    const breadcrumbsSections = [
      { id: 1, name: 'Vehículos 0Km', link: parseRoute('families') },
      {
        id: 2,
        name: brandName,
        link: parseRoute('family', { brand: brandName })
      },
      { id: 3, name: family.name, link: '' }
    ];

    const hero = getHeroImage(family);

    return (
      <div className="versions">
        <Helmet>
          <title>
            {brandName} {family.name} 0km
          </title>
          <meta
            name="description"
            content={`Territorio Yacopini - ${brandName} ${family.name}`}
          />
          <meta
            name="keywords"
            content="Territorio, Yacopini, Volkswagen, Chevrolet, Nissan, Ducati, vehiculos, autos, compra, 0km, planes de ahorro, autoahorro"
          />
        </Helmet>

        <DesktopHeader>
          <Header active={1} />
        </DesktopHeader>
        <MobileHeader>
          <MobileNavigation active={1} />
        </MobileHeader>
        <Hero
          type="background"
          big
          image={hero.hero}
          imageAlt={`${brandName} ${family.name}`}
          dark={hero.dark}
          breadcrumbs={breadcrumbsSections}
        >
          <div className="col col-five col-m-twelve versions__hero">
            <h3>{brandName}</h3>
            <h1>{family.name.toLowerCase()}</h1>
            <div dangerouslySetInnerHTML={{ __html: family.description }} />
            <br />
            {!priceRequested && (
              <Button
                text="Consultar precios"
                variant="main"
                color={theme}
                onClick={this.showPriceRequest}
              />
            )}
            {priceRequested && (
              <Button
                text="Encontrá la versión para vos"
                variant="main"
                color={theme}
                onClick={this.scrollToVersions}
              />
            )}{' '}
            <br />
            <Button
              onClick={() =>
                this.contact?.scrollIntoView({ behavior: 'smooth' })
              }
              text="Contactate con un asesor"
              variant="main"
            />
          </div>
        </Hero>
        {/*<Mobile>
          <RowButton
            text="Comparar Versiones"
            link="/comparar-versiones"
            icon
          />
        </Mobile>*/}
        <div
          className="section section-white"
          ref={section => {
            this.versions = section;
          }}
        >
          {brandName !== brands.nissan.name && (
            <Desktop>
              <div className="container">
                <div className="col col-twelve ">
                  <div className="section section-small section-center">
                    <ToggleButton
                      on={this.state.showCharacteristics}
                      title="Comparar versiones"
                      onClick={this.handleClick}
                    />
                  </div>
                </div>
              </div>
            </Desktop>
          )}

          <div className="container">
            {subfamilies.length === 0 && <Loader />}

            {stock !== null &&
              subfamilies.map(subfamily => {
                if (stockBySubfamily[subfamily.id] === undefined) {
                  return <Loader key={subfamily.id} />;
                }

                const hasUnits = stockBySubfamily[subfamily.id].all.length > 0;

                const price =
                  stockBySubfamily[subfamily.id] &&
                  stockBySubfamily[subfamily.id].all.length
                    ? stockBySubfamily[subfamily.id].best[0].price
                    : null;
                const visiblePrice = priceRequested ? price : null;

                let onClick;

                if (!priceRequested) {
                  onClick = this.showPriceRequest;
                } else {
                  onClick = hasUnits
                    ? () => this.showUnits(subfamily)
                    : () => this.formRef?.scrollIntoView({ behavior: 'smooth' });
                }

                return (
                  <div
                    className="col col-four col-t-six col-m-twelve"
                    key={subfamily.id}
                  >
                    <VersionCard
                      model={family.name}
                      version={subfamily.name}
                      disabled={!hasUnits}
                      price={visiblePrice}
                      currency={this.props.brandId === 6 ? 'usd' : 'ars'}
                      image={family.image}
                      onClick={onClick}
                      features={subfamily.features}
                      open={this.state.showCharacteristics}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <HowToBuyBanner />
        <div
          className="section section-alt section-big"
          ref={section => {
            this.contact = section;
          }}
        >
          <div ref={section => (this.formRef = section)}>
            <ContactForm subject={`${family.name}`}>
              <h2 className="heading-adjust">¿Necesitas más información?</h2>
              <p>
                Escribenos y un asesor de ventas te contactará para brindarte la
                mejor atención y asesorarte en la compra de tu nuevo 0km.
              </p>
              <p>
                O acercate a nuestras oficinas y te brindaremos el mejor
                asesoramiento.
              </p>
            </ContactForm>
          </div>
        </div>
        <Footer alt />
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="versions">
        <Loader />
      </div>
    );
  }

  renderNotFound() {
    return (
      <div className="versions">
        <h1>No encontramos este vehículo en nuestro catálogo.</h1>
        <Link to="/">Volver</Link>
      </div>
    );
  }

  setFamily() {
    const { families } = this.props;
    const familyId = this.props.match.params.id;

    if (this.state.family || families.length === 0) {
      return;
    }

    for (const family of families) {
      if (family.id === familyId) {
        this.setState({ family });

        this.props.fetchSubfamilies(familyId);
        return;
      }
    }
  }

  showUnits(subfamily) {
    this.setState({ showSubfamilyStock: subfamily, scrollY: window.scrollY });
  }

  hideUnits() {
    this.setState({ showSubfamilyStock: null });
  }

  showPriceRequest() {
    this.setState({ showPriceRequest: true });
  }

  hidePriceRequest() {
    this.setState({ showPriceRequest: false });
  }

  priceRequestSuccess() {
    sessionStorage.setItem('priceRequested', true);
    this.setState({ priceRequested: true, showPriceRequest: false });
  }

  restoreScroll(prevState) {
    if (prevState.showSubfamilyStock && !this.state.showSubfamilyStock) {
      window.scrollTo(0, this.state.scrollY);
    }

    if (!prevState.priceRequested && this.state.priceRequested) {
      this.scrollToVersions();
    }
  }

  scrollToVersions() {
    this.versions?.scrollIntoView({ behavior: 'smooth' });
  }
}

const mapStateToProps = state => {
  return {
    brandId: state.brand.active,
    brandName: state.brand.name,
    theme: state.brand.theme,
    families: state.family.all,
    subfamilies: state.subfamily.all,
    fetchingStock: state.stock.requesting,
    stock: state.stock.all,
    stockBySubfamily: state.stock.bySubfamily
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reset: () => {
      dispatch(stockActions.reset());
      dispatch(subfamilyActions.reset());
    },
    fetchSubfamilies: familyId =>
      dispatch(subfamilyActions.fetchAll({ query: { family_id: familyId } })),
    switchToBrand: name => dispatch(switchToBrandName(name)),
    fetchStock: (familyId, subfamilyId) =>
      dispatch(
        stockActions.fetchAll({
          query: { family_id: familyId, subfamily_id: subfamilyId }
        })
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Versions);
