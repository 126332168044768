import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import Responsive from 'react-responsive';
import FlowProgressBar from 'containers/digitalDealership/FlowProgressBar.jsx';
import FlowNavigation from 'components/molecules/flowNavigation/index.jsx';
import FlowHeader from 'components/molecules/flowHeader/index.jsx';
import Textfield from 'components/molecules/textfield/index.jsx';
import Button from 'components/atoms/button/index.jsx';
import Card from 'components/atoms/card/index.jsx';
import BalanceInformation from 'components/molecules/balanceInformation/index.jsx';

import * as dealershipActions from 'actions/dealership';
import { parseRoute } from 'config/routes';
import { getBrandName } from 'config/brands';
import { display, displayUSD } from 'helpers/price';

import '../DigitalDealership.scss';

const Desktop = props => <Responsive {...props} minWidth={1152} />;
const Mobile = props => <Responsive {...props} maxWidth={1151} />;

class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cash_amount: props.operation ? parseInt(props.operation.cash_amount) : 0,
      has_used_vehicle: false
    };

    this.handleCashAmountChange = this.handleCashAmountChange.bind(this);
    this.fillCashAmount = this.fillCashAmount.bind(this);
  }

  handleCashAmountChange(e) {
    e.preventDefault();

    const { operation, vehicle } = this.props;
    const value = parseFloat(e.target.value) || 0;
    const cash_amount =
      value > vehicle.full_price_with_discount
        ? vehicle.full_price_with_discount
        : value;

    this.setState({
      cash_amount
    });

    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    this.updateTimeout = setTimeout(() => {
      this.props.updateOperation({ ...operation, cash_amount });
    }, 500);
  }

  fillCashAmount(e) {
    e.preventDefault();

    const { operation, vehicle } = this.props;
    const { cash_amount } = this.state;
    const filledAmount =
      cash_amount + operation.getPendingAmount(vehicle, { cash_amount });

    this.setState({
      cash_amount: filledAmount
    });

    this.props.updateOperation({ ...operation, cash_amount: filledAmount });
  }

  removeUsedVehicle(index) {
    if (!window.confirm('¿Querés remover este vehículo usado?')) {
      return;
    }

    const { operation } = this.props;

    operation.used_vehicles.splice(index, 1);

    this.props.updateOperation(operation);
  }

  render() {
    const { vehicle, operation } = this.props;
    const { cash_amount } = this.state;
    const { operation_code } = this.props.match.params;

    if (!operation || !vehicle) {
      return (
        <Redirect
          to={parseRoute('dealership_resume', {
            operation_code: operation_code
          })}
        />
      );
    }

    const paidInFull =
      operation.getPendingAmount(vehicle, { cash_amount }) === 0;

    const displayPrice = operation.brand_id === 6 ? displayUSD : display;

    return (
      <div className="digital-dealership digital-dealership__payment-methods">
        <Helmet>
          <title>Concesionario Digital - Territorio Yacopini</title>
        </Helmet>

        <FlowHeader
          small
          title="Concesionario Digital"
          subtitle={`Reservando: ${getBrandName(operation.brand_id)} ${
            operation.purchase.vehicle
          }`}
        />

        <BalanceInformation
          operation={operation}
          cashAmount={cash_amount}
          vehicle={vehicle}
        />

        <div className="section">
          <div className="container">
            <div className="col col-twelve">
              <h5>Compra Online</h5>
              <h2>1. Modo de pago</h2>
              <Desktop>
                <p className="p">
                  Seleccioná la forma de pago que querés utilizar para la compra
                  del vehículo. Para ello debes ingresar el importe a entregar
                  en efectivo, agregar vehículos usados y/o seleccionar alguno
                  de nuestros planes de financiación.
                </p>
              </Desktop>
              <Mobile>
                Seleccioná los modos en que realizaras el pago. Debes lograr que
                tu saldo pendiente sea $ 0.
              </Mobile>
            </div>
          </div>
        </div>
        <div className="section section-small">
          <div className="container">
            <div className="col col-twelve">
              <Card nopadding>
                <div className="PaymentMethodRow">
                  <div className="PaymentMethodRow__header">
                    <h3>Dinero en Efectivo</h3>
                  </div>
                  <div className="PaymentMethodRow__item">
                    <div className="PaymentMethodRow__item__data">
                      <h4>Monto a abonar</h4>
                    </div>
                    <div className="PaymentMethodRow__item__price">
                      <Textfield
                        value={cash_amount}
                        onChange={this.handleCashAmountChange}
                      />
                    </div>
                  </div>
                  <div className="PaymentMethodRow__content">
                    <div className="PaymentMethodColumn">
                      <p className="p p-s">
                        Ingresa el monto en efectivo que dispones para la compra
                        del vehículo. Si no querés sacar cuentas podés
                        completar el saldo restante.
                      </p>
                    </div>
                    <div className="PaymentMethodColumn">
                      {!paidInFull && (
                        <a
                          href="#completeRemaining"
                          onClick={this.fillCashAmount}
                        >
                          Completar Restante{' '}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="PaymentMethodRow">
                  <div className="PaymentMethodRow__header">
                    <h3>Vehículos Usados</h3>
                  </div>
                  {operation.used_vehicles.map((usedVehicle, i) => (
                    <div className="PaymentMethodRow__item" key={i}>
                      <div className="PaymentMethodRow__item__data">
                        <h4>
                          {usedVehicle.brand} {usedVehicle.vehicle}
                        </h4>
                        <a
                          href="#remove-vehicle"
                          onClick={e => {
                            e.preventDefault();
                            this.removeUsedVehicle(i);
                          }}
                        >
                          Eliminar
                        </a>
                      </div>
                      {usedVehicle.valuation > 0 && (
                        <div className="PaymentMethodRow__item__price">
                          <h4>{displayPrice(usedVehicle.valuation)}</h4>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="PaymentMethodRow__content">
                    <div className="PaymentMethodColumn">
                      <p className="p p-s">
                        Como parte de pago, podés agregar hasta 3 vehículos
                        usados. Ingresa los datos del vehículo y sus fotos para
                        su cotización.
                      </p>
                    </div>
                    <div className="PaymentMethodColumn">
                      {/**<Link
                          to={parseRoute('dealership_used_vehicle', {
                            operation_code
                          })}
                        >
                          <Button
                            text={`Agregar ${
                              operation.used_vehicles.length ? 'Otro' : 'Vehículo'
                            } Usado`}
                            variant={operation.used_vehicles.length ? '' : 'main'}
                            disabled={paidInFull}
                          />
                        </Link> */}
                        No disponible online.
                    </div>
                  </div>
                </div>
                <div className="PaymentMethodRow">
                  <div className="PaymentMethodRow__header">
                    <h3>Financiación</h3>
                  </div>
                  {operation.brand_id !== 6 ? (                                
                    <div className="PaymentMethodRow__content">
                      <div className="PaymentMethodColumn">
                        {operation.credit_id > 0 && (
                          <p className="p p-s">
                            {operation.credit_name}. Cuota promedio{' '}
                            {displayPrice(operation.credit_avg_installment)} en{' '}
                            {operation.credit_installments} meses.
                          </p>
                        )}
                        {!operation.credit_id && (
                          <p className="p p-s">
                            Disponemos de distintas líneas de financiación para la
                            compra de tu próximo 0KM.
                          </p>
                        )}
                      </div>
                      <div className="PaymentMethodColumn">
                        <Link
                          to={parseRoute('dealership_credit_selection', {
                            operation_code
                          })}
                        >
                          <Button
                            text={
                              operation.credit_id
                                ? 'Modificar financiación'
                                : 'Seleccionar financiación'
                            }
                            variant="main"
                            disabled={paidInFull && !operation.credit_id}
                          />
                        </Link>
                      </div>
                  </div>
                  ) : (
                    <div className="PaymentMethodRow__content">
                      <div className="PaymentMethodColumn">
                        <p className="p p-s">
                            Disponemos de distintas líneas de financiación para la
                            compra de tu próximo 0KM.
                          </p>
                      </div>
                      <div className="PaymentMethodColumn">
                        Próximamente.
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>

        <FlowNavigation progressBar={<FlowProgressBar step={1} />}>
          <Link
            to={parseRoute('dealership_personal_information', {
              operation_code
            })}
          >
            <Button
              text="Continuar"
              variant="main"
              disabled={!paidInFull && operation.used_vehicles.length === 0}
            />
          </Link>
        </FlowNavigation>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operation: state.dealership.operation,
    vehicle: state.stock.selected
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOperation: operation => dispatch(dealershipActions.update(operation))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
